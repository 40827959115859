import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import SelectedStep from "../Components/SelectedStep";
import UpcomingStep from "../Components/UpcomingStep";
import styles from './journey.module.css'
import '../App.css'
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {ReactComponent as ExpandIcon} from "../assets/ic_card_arrow_collapse.svg";
import useGetCandidate from "../hooks/useGetCandidate";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";
import {useNavigate} from "react-router";

const SuccessRejectionView = ({title, subTitle, description, titleColor}) => {
    return <Box sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
    }}>
        <Box sx={{
            backgroundColor: 'white',
            borderRadius: '32px 32px 0 0',
            padding: '60px 30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px'
        }}>
            <Typography
                sx={{fontSize: 24, fontWeight: 600, textAlign: 'center', color: titleColor}}>{title}</Typography>
            <Typography sx={{fontSize: 16, fontWeight: 700, textAlign: 'center'}}>{subTitle}</Typography>
            <ExpandIcon/>
            <Typography sx={{fontSize: 16, fontWeight: 400, textAlign: 'center'}}>{description}</Typography>
            <Button component={'a'} sx={{
                width: '245px',
                height: '60px',
                fontSize: '16px',
                borderRadius: '16px',
                fontWeight: 600,
                backgroundColor: 'black',
            }} variant="contained" target={'_blank'} href={'https://bluexp.netapp.com/tlvjobs'}>Career page</Button>
        </Box>
    </Box>

}

const Journey = () => {
    const {data} = useGetCandidate()
    const steps = data?.data?.position?.steps || []
    const currentStep = data?.data?.currentStep || 0
    const [selectedStep, setSelectedStep] = useState(currentStep)
    const currentSelectedStep = steps ? steps[selectedStep] : []

    const navigate = useNavigate();
    const [seenWelcomePage,setSeenWelcomePage] = useStateWithLocalStorage('seenWelcomePage', '')

    useEffect(()=>{
        if(!seenWelcomePage) {
            setSeenWelcomePage('true')
            setTimeout(()=>{
                navigate('/welcome')
            },100)
        }
    },[seenWelcomePage, navigate, setSeenWelcomePage])

    return <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow:'hidden',
        position: 'relative'
    }}>
        {currentStep === 100 ?
            <SuccessRejectionView title={'Congratulations!'} subTitle={`We are so excited to have you join us`}
                                  titleColor={'#0BAFFC'}
                                  description={`Now it's time to bring your friends! 
Check out our open positions`}/> : currentStep === -100 ?
                <SuccessRejectionView titleColor={'#A93CFF'} title={'Thank you'}
                                      subTitle={`
 We appreciate your time & effort
and wish you good luck in
   your job search`}
                                      description={`Check back often as new positions open up `}/> : <>
                    <Box sx={{
                        flex: 1,
                        position: 'relative',
                        display: "flex",
                        overflow:'hidden',
                        flexDirection: 'column'

                    }}>
                        <Box sx={{
                            zIndex: 15,
                            padding: '0 16px',
                            width: '100%',
                            position: 'absolute'
                        }}>
                            <UpcomingStep/>
                        </Box>
                        <Box sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            position: "relative",
                            overflow:'auto',
                            paddingTop:'65px',
                            justifyContent:'space-evenly'
                        }}>
                            {steps.map((step, index) => {
                                return <Box key={step.name} onClick={() => setSelectedStep(index)}
                                            className={`${currentStep === index ? styles.active : ''} ${selectedStep === index ? 'selected-step' : ''} ${currentStep > index ? styles.passed : ''}`}
                                            sx={{
                                                width: '88px',
                                                boxSizing: 'content-box',
                                                border: '4px solid transparent',
                                                height: '88px',
                                                borderRadius: '50%',
                                                order:steps.length-index,
                                                margin: index % 2 === 0 ? '0 auto 0 60px':'0 60px 0 auto',
                                                '& svg': {
                                                    width: '100%',
                                                    height: '100%',
                                                }
                                            }}><img src={step.icon} alt={''}/></Box>
                            })}
                        </Box>

                    </Box>
                    <Box sx={{
                        overflow: 'hidden',
                        borderRadius: '32px 32px 0 0',
                        flexBasis:'96px'
                    }}>
                        <SelectedStep currentSelectedStep={currentSelectedStep} stepIndex={selectedStep}/>
                    </Box>
                </>}
    </Box>
}

export default Journey;