import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Link} from "react-router-dom";

const routes = [{
    label: 'My journey',
    value: '/'
},
    {
        label: 'About us',
        value: '/Welcome'
    },
    {
        label: 'Life at NetApp',
        value: '/life-at-netapp'
    },
    {
        label: 'Prep for our interviews',
        value: '/interview-prep'
    },
    {
        label: 'At the media',
        value: '/story'
    },
    {
        label: 'Meet the team',
        value: '/meet-the-team'
    },
]
const MenuList = ({setIsOpen}) => (
    <Box
        sx={{
            width: '70vw',
            paddingTop: '60px',
        }}
        role="presentation"
        onClick={() => setIsOpen(false)}
        onKeyDown={() => setIsOpen(false)}
    >
        <List>
            {routes.map(({label, value}) => (
                <ListItem key={label} disablePadding>
                    <ListItemButton component={Link} selected={label === 'My journey'} to={value} sx={{
                        paddingLeft: '40px',
                    }}>
                        <ListItemText primary={label}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>

    </Box>
);

const Menu = ({isOpen, setIsOpen}) => {
    return <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
    >
        <MenuList setIsOpen={setIsOpen}/>
    </Drawer>
}

export default Menu;