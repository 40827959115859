import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import {Transition} from "../Components/FullPageWelcom";
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {ReactComponent as CloseIcon} from "../assets/close.svg";
import RobotIcon from '../assets/robot.png';
import BulbGlobe from '../assets/bulb-globe.png';
import useGetCandidate from "../hooks/useGetCandidate";
import Tabs from "@mui/material/Tabs";
import SwipeableViews from 'react-swipeable-views';
import Tab from "@mui/material/Tab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const InterviewPrep = (props) => {
    const {data} = useGetCandidate()
    const [selectedTab,setSelectedTab]=useState(0);
    return <Dialog
        fullScreen
        open={true}
        TransitionComponent={Transition}
        scroll={'body'}
    >
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            whiteSpace: 'break-spaces',
        }}>
            <IconButton component={Link} sx={{
                marginLeft: 'auto',
                marginBottom: '31px'
            }} to={'/'}><CloseIcon/></IconButton>

            <Typography sx={{fontSize: 24, fontWeight: 600, paddingBottom: '30px'}}>Prep for our interviews</Typography>
            <img src={RobotIcon} style={{paddingBottom: '24px'}} alt={''}/>
            <Tabs sx={{width:'100%'}} value={selectedTab} variant="fullWidth" onChange={(e,newValue)=>{
                setSelectedTab(newValue)
            }} >
                <Tab label="Prep" />
                <Tab label="Skills" />
            </Tabs>
            <SwipeableViews
                axis={'x'}
                index={selectedTab}
                onChangeIndex={setSelectedTab}
            >
                <TabPanel value={selectedTab} index={0}>
                    {data?.data?.position?.prepForInterview?.prep && <><Typography sx={{fontSize: '16px', fontWeight: 600, width: '100%', padding: '32px 0 16px'}}
                                                                               align={'left'}>You will be asked to demonstrate capabilities in the following areas: </Typography>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingBottom: '16px',
                        }}>{data?.data?.position?.prepForInterview?.prep}</Typography></>}
                    {data?.data?.position?.prepForInterview?.topics && <><Typography sx={{fontSize: '16px', fontWeight: 600, width: '100%', padding: '32px 0 16px'}}
                                                                                   align={'left'}>General Topics- before the interview</Typography>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingBottom: '16px',
                        }}>{data?.data?.position?.prepForInterview?.topics}</Typography></>}
                    {data?.data?.position?.prepForInterview?.during && <><Typography sx={{fontSize: '16px', fontWeight: 600, width: '100%', padding: '32px 0 16px'}}
                                                                                   align={'left'}>During the interview</Typography>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            paddingBottom: '16px',
                        }}>{data?.data?.position?.prepForInterview?.during}</Typography></>}
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Typography sx={{padding: '16px 24px 0'}}>
                        {data?.data?.position?.prepForInterview?.skills}
                    </Typography>
                </TabPanel>
            </SwipeableViews>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '28px',
                alignItems: "center",
                width: '100%',
                margin: '49px 10px 35px',
                padding: '16px 34px ',
                boxSizing: 'border-box',
                borderRadius: '16px',
                background: 'linear-gradient(180deg, #02198E 0%, #15009A 47.4%, #6351D1 100%)'
            }}>
                <Typography sx={{fontSize: 24, fontWeight: 600, color: "white", textAlign: 'center'}}>We are excited to
                    meet you! good luck in your journey with us, we are available for any questions you may
                    have.</Typography>
                <Typography sx={{fontSize: 16, fontWeight: 600, color: "white"}}>NetApp HR Team</Typography>
                <img src={BulbGlobe} alt={''}/>
            </Box>
        </Box>
    </Dialog>
}

export default InterviewPrep;