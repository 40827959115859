import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Outlet} from "react-router-dom";
import styles from './Root.module.css'
import Menu from "../Components/Menu";
import {ReactComponent as MessageIcon} from "../assets/message.svg";
import {SelectedAvatar} from "../Components/FullPageWelcom";
import Skeleton from "@mui/material/Skeleton";
import errorImg from '../assets/error.png'
import Button from "@mui/material/Button";
import useGetCandidate from "../hooks/useGetCandidate";

export default function Root() {
    const {data, isLoading, error} = useGetCandidate()
    const {name} = data?.data || {};
    const [isOpen, setIsOpen] = useState(false)
    if (error) {
        return <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '52px'
        }}>
            <img src={errorImg} alt={'error'} style={{width: '182px'}}/>
            <Typography sx={{fontSize: 16, fontWeight: 600}}>Result not found</Typography>
            <Typography sx={{width: '350px', textAlign: 'center'}}>Whoops... this information is
                not available for a moment</Typography>
            <Button component={'a'} sx={{
                height: '60px',
                width: '245px',
                fontSize: '16px',
                borderRadius: '16px',
                fontWeight: 600,
                backgroundColor: 'black',
                marginBottom: '64px'
            }} variant="contained" target={'_blank'} href={'https://wa.me/+972502507074'}>Contact us</Button>
        </Box>
    }
    return (
        <Box className={`${styles.base} ${data?.data?.currentStep === 100 ? styles.takeoff : ''}`} sx={{
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgImage: `url('../assets/background-journey.svg')`
        }}>
            {isLoading ? <Box sx={{
                height: '100%',
                padding: '32px 32px 0',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '14px'
                }}>
                    <Skeleton variant="circular" width={40} height={40}/>
                    <Box sx={{
                        flex: 1
                    }}/>
                    <Skeleton variant="rounded" width={30} height={30}/>
                    <Skeleton variant="rounded" width={30} height={30}/>
                </Box>
                <Skeleton variant="rounded" width={'100%'} height={96}/>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    position: "relative"
                }}>
                    {['', '', '', ''].map((step, index) => {
                        return <Box key={index} sx={{
                            position: 'absolute',
                            width: '88px',
                            boxSizing: 'content-box',
                            border: '4px solid transparent',
                            height: '88px',
                            borderRadius: '50%',
                            bottom: `${index * 100 + 50}px`,
                            left: index % 2 === 0 ? '50px' : 'unset',
                            right: index % 2 === 1 ? '50px' : 'unset',
                        }}><Skeleton variant="circular" width={88} height={88}/></Box>
                    })}
                </Box>
                <Skeleton variant="rounded" width={'100%'} height={60}/>
            </Box> : <>
                <AppBar position="static" sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }}>
                    <Toolbar sx={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    }}>
                        <SelectedAvatar showAll={false}/>
                        <Typography variant="h6" component="div" sx={{
                            pl: 2,
                            flexGrow: 1,
                            fontWeight: 600,
                            fontSize: 16,
                            color: 'black'
                        }}>
                            {`Hey ${name}!`}
                        </Typography>
                        <IconButton
                            component={"a"}
                            target={"_blank"}
                            href={'https://wa.me/+972502507074'}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MessageIcon style={{color: "black", width: '20px', marginRight: '8px'}}/>
                        </IconButton>
                        <IconButton
                            onClick={() => setIsOpen(prev => !prev)}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon sx={{color: "black"}}/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Menu isOpen={isOpen} setIsOpen={setIsOpen}/>
                <Outlet/>
            </>}
        </Box>
    );
}