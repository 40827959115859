import * as React from 'react';
import PropTypes from 'prop-types';
import {Global} from '@emotion/react';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {grey} from '@mui/material/colors';
import Box from '@mui/material/Box';
import {ReactComponent as LookoutIcon} from "../assets/lookout.svg";
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {ReactComponent as ArrowIcon} from '../assets/ic_arrow.svg'
import avatarImg from "../assets/person-icon.png";

const Root = styled('div')(({theme}) => ({
    height: '100%',
}));

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(ArrowIcon)(({isOpen}) => ({
    top:'4px',
    width: '24px',
    height: '24px',
    position: 'absolute',
    left: 'calc(50% - 12px)',
    transform: isOpen?'rotate(180deg)':'none'
}));

function SwipeableEdgeDrawer(props) {
    const {window, currentSelectedStep, stepIndex} = props;
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline/>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(100% - 96px)`,
                        overflow: 'visible',
                        maxWidth:'600px',
                        margin:'0 auto'
                    },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClick={toggleDrawer(!open)}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top:'-96px',
                        height:'96px',
                        borderTopLeftRadius: 24,
                        borderTopRightRadius: 24,
                        visibility: 'visible',
                        right: 0,
                        pointerEvents:'all',
                        left: 0
                    }}
                >
                    <Puller isOpen={open}/>
                    <Box sx={{
                        height:'100%',
                        display: 'flex',
                        gap: '4px',
                        padding: '24px 8px 8px 8px ',
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Typography sx={{fontWeight: 600}} Component={'span'} noWrap
                                    color={'#CF74E4'}>{`Step ${stepIndex + 1}`}</Typography>
                        <Typography sx={{fontWeight: 600}} Component={'span'} color={'#2391FF'}>-</Typography>
                        <Typography sx={{fontWeight: 600}} noWrap>{currentSelectedStep?.stepLabel}</Typography>
                    </Box>
                </StyledBox>
                <StyledBox
                    sx={{
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 40px 16px',
                        gap: '4px',
                        whiteSpace: 'break-spaces',
                        flexDirection: 'column',
                        '*': {
                            flexShrink: '0'
                        }
                    }}
                >
                    <Typography sx={{fontSize: '24px', fontWeight: 600, paddingTop:'16px'}}>{currentSelectedStep?.stepLabel}</Typography>
                    <Typography sx={{
                        fontSize: '12px',
                        fontWeight: 300,
                        paddingBottom: '16px'
                    }}>({currentSelectedStep?.duration})</Typography>
                    <img src={currentSelectedStep?.icon} alt={''}/>
                    <Box sx={{
                        display:'flex',
                        width:'100%',
                        alignItems:'baseline',
                        justifyContent:'space-evenly'
                    }}>
                        {currentSelectedStep?.interviewers?.map((interviewer) => {
                            return <Box sx={{
                                width:'130px',
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                padding:'16px 0',
                                gap:'4px'
                            }}>
                                <img src={interviewer?.img || avatarImg} alt={'interview'} style={{
                                    width: '48px',
                                    borderRadius: '50%'
                                }}/>
                                <Typography sx={{
                                    textAlign:'center',
                                    fontWeight:'600'
                                }}>{interviewer.name}</Typography>
                                <Typography sx={{
                                    textAlign:'center'
                                }}>{interviewer.title}</Typography>

                            </Box>
                        })}
                    </Box>
                    {currentSelectedStep?.description && <><Typography sx={{fontSize: '16px', fontWeight: 600, width: '100%', padding: '32px 0 16px'}}
                                align={'left'}>What to expect?</Typography>
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        paddingBottom: '16px',
                    }} >{currentSelectedStep?.description}</Typography></>}
                    {currentSelectedStep?.whatAreWeLookingFor && <><Typography sx={{fontSize: '16px', fontWeight: 600, width: '100%', padding: '32px 0 16px'}}
                                align={'left'}>What we are looking for?</Typography>
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        paddingBottom: '16px',
                    }}>{currentSelectedStep?.whatAreWeLookingFor}</Typography></>}
                    {currentSelectedStep?.whyDoWeAsk && <><Typography sx={{fontSize: '16px', fontWeight: 600, width: '100%', padding: '32px 0 16px'}}
                                align={'left'}>Why do we ask?</Typography>
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        paddingBottom: '16px',
                    }}>{currentSelectedStep?.whyDoWeAsk}</Typography></>}
                    <LookoutIcon/>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}

SwipeableEdgeDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SwipeableEdgeDrawer;