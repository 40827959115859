import * as React from 'react';
import {useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import styles from './FullPageWelcome.module.css';
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import NetappLogo from '../assets/NetApp.png'
import Paper from "@mui/material/Paper";
import funnyImage from '../assets/picture-mail.png'
import avatar1 from "../assets/avatars/avatar1.png";
import avatar2 from "../assets/avatars/avatar2.png";
import avatar3 from "../assets/avatars/avatar3.png";
import avatar4 from "../assets/avatars/avatar4.png";
import avatar5 from "../assets/avatars/avatar5.png";
import avatar6 from "../assets/avatars/avatar6.png";
import avatar7 from "../assets/avatars/avatar7.png";
import avatar8 from "../assets/avatars/avatar8.png";
import avatar9 from "../assets/avatars/avatar9.png";
import avatar10 from "../assets/avatars/avatar10.png";
import avatar11 from "../assets/avatars/avatar11.png";
import avatar12 from "../assets/avatars/avatar12.png";
import ButtonBase from "@mui/material/ButtonBase";
import Avatar from "@mui/material/Avatar";
import {GeneralContext} from "../App";
import {IconButton} from "@mui/material";
import {ReactComponent as CloseIcon} from "../assets/close.svg";
import useGetCandidate from "../hooks/useGetCandidate";

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const netAppTlvTitle = <>At the <b>NetApp Cloud Innovation Center TLV</b></>

export const reasonsToWorkWithUsTitle = `3+ Reasons why this position is worth your time:`

export default function FullScreenDialog() {
    const {data} = useGetCandidate()
    const aboutUs = data?.data?.position?.aboutUs[0];
    return (
        <Dialog
            fullScreen
            open={true}
            TransitionComponent={Transition}
            scroll={'body'}
        >
            <Box className={styles.base} sx={{
                height: '3656px',
                maxWidth:'600px',
                margin:'0 auto',
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                padding: '350px 0 130px',
                position:'relative',
                whiteSpace: 'break-spaces'
            }}>
                <IconButton component={Link} sx={{
                    right: '32px',
                    position: 'absolute',
                    top: '16px',
                    svg: {
                        path: {
                            stroke: 'white'
                        }
                    }
                }} to={'/'}><CloseIcon/></IconButton>
                <Box sx={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    alignItems: "center",
                }}>
                    <Typography sx={{fontSize: 14, color: "white"}}>Welcome to </Typography>
                    <img src={NetappLogo} alt={'logo'}/>
                    <Typography sx={{fontSize: 16, color: "white", padding: '60px'}}>
                        {aboutUs?.generalInfo}
                    </Typography>
                    <Box sx={{height: '500px'}}/>
                    <Paper sx={{padding: '30px', boxSizing: 'content-box', margin: '32px', borderRadius: '32px'}}>
                        <Typography sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            paddingBottom: '16px'
                        }}>{netAppTlvTitle}</Typography>
                        <Typography sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            paddingBottom: '16px'
                        }}>{aboutUs?.inTelAviv}</Typography>
                        <img src={funnyImage} alt={'funny env'}/>
                    </Paper>
                    <iframe width="80%" height="315" src="https://www.youtube.com/embed/osoD5yXvYOA"
                            style={{borderRadius: '32px'}}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                    <Box sx={{height: '60px'}}/>
                    <Paper sx={{padding: '30px', boxSizing: 'content-box', margin: '32px', borderRadius: '32px'}}>
                        <Typography sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            paddingBottom: '16px'
                        }}><b>{reasonsToWorkWithUsTitle}</b></Typography>
                        <Typography sx={{fontWeight: 400, fontSize: '16px'}}>
                            {aboutUs.reasons}
                        </Typography>
                    </Paper>
                    <Box sx={{height: '100px'}}/>
                    <Paper sx={{
                        padding: '30px',
                        boxSizing: 'content-box',
                        margin: '32px',
                        borderRadius: '32px',
                        overflow: 'hidden'
                    }}>
                        <Typography sx={{fontWeight: 400, fontSize: '16px', paddingBottom: '16px'}}><b>Choose your
                            avatar:</b></Typography>
                        <SelectedAvatar showAll={true}/>
                    </Paper>
                </Box>

                <Button component={Link} sx={{
                    height: '60px',
                    width: '245px',
                    fontSize: '16px',
                    borderRadius: '32px',
                    fontWeight: 600,
                    backgroundColor: 'black'
                }} variant="contained" to={'/'}>My journey</Button>
            </Box>
        </Dialog>
    );
}

const AvatarButton = ({img, name, selectedAvatar, setSelectedAvatar}) => {
    return <ButtonBase onClick={() => setSelectedAvatar(name)} sx={{
        boxShadow: selectedAvatar === name ? '0 0 10px 6px #A93CFF' : 'none',
        borderRadius: '25px'
    }}>
        <Avatar src={img} alt={name} width={'53px'} height={'53px'}/>
    </ButtonBase>
}

const avatars = {
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
}

export const SelectedAvatar = ({showAll}) => {
    const [selectedAvatar, setSelectedAvatar] = useContext(GeneralContext).avatarState
    if (showAll) {
        return <Box sx={{display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '30px'}}>
            <AvatarButton img={avatar1} name={'avatar1'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar2} name={'avatar2'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar3} name={'avatar3'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar4} name={'avatar4'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar5} name={'avatar5'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar6} name={'avatar6'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar7} name={'avatar7'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar8} name={'avatar8'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar9} name={'avatar9'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar10} name={'avatar10'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar11} name={'avatar11'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
            <AvatarButton img={avatar12} name={'avatar12'} selectedAvatar={selectedAvatar}
                          setSelectedAvatar={setSelectedAvatar}/>
        </Box>
    }
    return <Avatar alt="Candiate" src={avatars[selectedAvatar]} sx={{
        width: '40px'
    }}/>
}