import React, {useMemo, useRef, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import {Transition} from "../Components/FullPageWelcom";
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {ReactComponent as CloseIcon} from "../assets/close.svg";
import SpaceShip from '../assets/space-ship.png';
import GlassDoor from '../assets/glassdoor.png';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageModal from "../Components/ImageModal";
import useGetCandidate from "../hooks/useGetCandidate";
import {isImage} from "../utils";
import {ReactComponent as ArrowUp} from "../assets/ic_arrow.svg";

const LifeAtNetapp = (props) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");
    const {data} = useGetCandidate()
    const images = useMemo(() => data?.data?.lifeAtNetappImages?.map(img => ({img})), [data?.data?.lifeAtNetappImages])
    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (value) => {
        setImage(value);
        setOpen(true);
    };
    return <Dialog
        fullScreen
        open={true}
        TransitionComponent={Transition}
        scroll={'body'}
        containerId={'dialogCon'}
    >
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            whiteSpace: 'break-spaces',
        }}>
            <IconButton component={Link} sx={{
                marginLeft: 'auto',
                marginBottom: '31px'
            }} to={'/'}><CloseIcon/></IconButton>

            <Typography sx={{fontSize: 24, fontWeight: 600, paddingBottom: '34px'}}>Life at NetApp</Typography>
            <img src={SpaceShip} style={{paddingBottom: '56px'}} alt={''}/>
            <img src={GlassDoor} alt={'Glassdoor reward'}/>

            <ImageList variant="quilted" cols={2} gap={8}>
                {images.map((item) => (
                    <ImageListItem key={item.img}>
                        {isImage(item.img) ? <img
                            onClick={() => handleImage(item)}
                            src={`${item.img}`}
                            alt={''}
                            loading="lazy"
                            style={{borderRadius: '16px'}}
                        /> : <video src={item.img}
                                    style={{
                                        borderRadius: '16px', width: '100%',
                                        height: '100%'
                                    }}
                                    controls/>}
                    </ImageListItem>
                ))}
            </ImageList>
            <ImageModal open={open} image={image} handleClose={handleClose}/>
        </Box>
        <IconButton sx={{
        position:'fixed',
            right:'16px',
            bottom:'16px',
            backgroundColor:'white'
        }} onClick={()=>{
            document.querySelector(`[role="dialog"]`).scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }}><ArrowUp /></IconButton>
    </Dialog>
}

export default LifeAtNetapp;