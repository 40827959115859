import React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import {ReactComponent as LinkedInIcon} from "../assets/linkedin.svg";
import {ReactComponent as CalendarIcon} from "../assets/calendar.svg";
import {ReactComponent as ClockIcon} from "../assets/Clock.svg";
import {ReactComponent as LocationIcon} from "../assets/location.svg";
import IconButton from "@mui/material/IconButton";
import moment from 'moment'
import avatarImg from '../assets/person-icon.png'
import useGetCandidate from "../hooks/useGetCandidate";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    width: '100%',
    borderRadius: '16px',
    overflow: 'hidden'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem', color: "white"}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: 'black',
    color: 'white',
    flexDirection: 'row-reverse',
    paddingRight: 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-root': {
        paddingRight: 0
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 0,
        height: '96px'
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const UpcomingStep = (props) => {
    const [isExpanded, setExpanded] = React.useState(false);
    const {data} = useGetCandidate()
    const interviewMomentDate = data?.data?.interviewDate ? moment(data?.data?.interviewDate) : null;
    const interviewLocation = data?.data?.interviewLocation || 'Contact HR for location'
    const interviewDate = interviewMomentDate?.format('DD MMM') || null;
    const interviewDateFull = interviewMomentDate?.format('dddd, DD MMM') || null;
    const interviewHour = interviewMomentDate?.format('hh:mm a') || null;
    const nextStep = data?.data?.position?.steps[data?.data?.currentStep || 0]

    return <Accordion expanded={isExpanded} onChange={() => setExpanded(prev => !prev)}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" sx={{
            display: 'flex'
        }}>
            <Box sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: "16px",
            }}>
                <Typography sx={{fontSize: '12px', fontWeight: 300, paddingBottom: '4px'}}>My next step:</Typography>
                <Typography sx={{fontSize: '16px', fontWeight: 400}}>{nextStep?.stepLabel}</Typography>
            </Box>
            {interviewDate && <Box sx={{
                backgroundColor: '#DDF1FF',
                color: 'black',
                border: '1px solid black',
                borderRadius: isExpanded ? '0 16px 0 0' : '0 16px 16px 0',
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                padding: "16px",
            }}>
                <Typography sx={{fontSize: '16px', fontWeight: 600}}>{interviewDate}</Typography>
                <Typography sx={{fontSize: '12px', fontWeight: 300}}>{interviewHour}</Typography>
            </Box>}
        </AccordionSummary>
        <AccordionDetails sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px'
        }}>
            <Typography>
                You'll be meeting:
            </Typography>
            <Box sx={{
                display: 'flex',
                width: '100%',
                padding: '0 16px 0 46px',
                gap: '20px',
                flexDirection: 'column'
            }}>
                {nextStep?.interviewers?.map(interviewer => {
                    return <Box key={interviewer?.name} sx={{
                        display: 'flex',
                        alignItems: "center",
                        gap: '16px',
                        backgroundColor: '#F4F1F1',
                        height: '80px',
                        borderRadius: '12px',
                        paddingLeft: '48px',
                        paddingRight: '8px',
                        position: 'relative'
                    }}>
                        <img src={interviewer?.img || avatarImg} alt={'interview'} style={{
                            position: 'absolute',
                            left: '-30px',
                            width: '65px',
                            borderRadius: '20px'
                        }}/>
                        <Box sx={{
                            flex: 1
                        }}>
                            <Typography sx={{fontSize: '16px', fontWeight: 600}}>{interviewer.name}</Typography>
                            <Typography sx={{fontSize: '12px', fontWeight: 300}}>{interviewer.title}</Typography>
                        </Box>
                        {interviewer.linkedinProfile && <IconButton
                            component={'a'}
                            href={interviewer.linkedinProfile}
                            target={"_blank"}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                        >
                            <LinkedInIcon/>
                        </IconButton>}
                    </Box>
                })}
            </Box>
            {interviewDateFull && <Box sx={{
                display: 'flex',
                marginTop: '40px',
                width: '100%'
            }}>
                <Box sx={{
                    flex: 1,
                    width: '100%',
                    padding: '0 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    gap: '12px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center'

                    }}>
                        <CalendarIcon/>
                        <Typography>{interviewDateFull}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center'
                    }}>
                        <ClockIcon/>
                        <Typography>{interviewHour}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center'
                    }}>
                        <LocationIcon/>
                        <Typography>{interviewLocation}</Typography>
                    </Box>
                </Box>
            </Box>}
        </AccordionDetails>
    </Accordion>
}

export default UpcomingStep;