import './App.css';
import {createMemoryRouter, RouterProvider} from "react-router-dom";
import React from "react";
import Root from "./routes/Root";
import Manage from "./routes/Manage";
import Journey from "./routes/Journey";
import Story from "./routes/Story";
import LifeAtNetapp from "./routes/LifeAtNetapp";
import Welcome from "./routes/Welcome";
import {QueryClient, QueryClientProvider, useQuery} from "react-query";
import useStateWithLocalStorage from "./hooks/useStateWithLocalStorage";
import InterviewPrep from "./routes/InterviewPrep";
import MeetTheTeam from "./routes/MeetTheTeam";
import queryString from "query-string";
import {getCandidate} from "./api";

const router = createMemoryRouter([
    {
        path: "/",
        element: <Root/>,

        children: [
            {
                path: "/",
                element: <Journey/>,
            },
            {
                path: "/welcome",
                element: <Welcome/>,
            },
            {
                path: "/story",
                element: <Story/>,
            },
            {
                path: "/life-at-netapp",
                element: <LifeAtNetapp/>,
            }, {
                path: "/meet-the-team",
                element: <MeetTheTeam/>,
            },
            {
                path: "/interview-prep",
                element: <InterviewPrep/>,
            },
        ],
    },
    {
        path: "/manage",
        element: <Manage/>,
    },
]);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
})
export const GeneralContext = React.createContext(null);

function App() {
    const avatarState = useStateWithLocalStorage('selectedAvatar', 'avatar2')

    const candidateId = queryString.parse(window.location.search).c
    const candidate = useQuery('getCandidate', () => getCandidate(candidateId))

    return (
        <GeneralContext.Provider value={{avatarState, candidate}}>
            <RouterProvider router={router}/>
        </GeneralContext.Provider>
    );
}

const AppWrapper = () => <QueryClientProvider client={queryClient}><App/></QueryClientProvider>;
export default AppWrapper