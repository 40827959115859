import React from 'react';
import Dialog from "@mui/material/Dialog";
import {Transition} from "../Components/FullPageWelcom";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {ReactComponent as CloseIcon} from "../assets/close.svg";
import styles from './MeetTheTeam.module.css'
import avatarImg from '../assets/person-icon.png'
import useGetCandidate from "../hooks/useGetCandidate";

const Worker = ({worker}) => {

    return <Box className={'worker'} sx={{
        flexBasis: '47%',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        alignItems: 'center'
    }}>
        <img src={worker.img || avatarImg} style={{width: '97px', height: '97px', borderRadius: '50%'}}
             alt={worker.name}/>
        <Typography sx={{fontSize: 16, fontWeight: 600}}>{worker.name}</Typography>
        <Typography sx={{fontSize: 12, fontWeight: 300}}>{worker.title}</Typography>
    </Box>
}

const MeetTheTeam = (props) => {
    const {data} = useGetCandidate()
    return <Dialog
        fullScreen
        open={true}
        TransitionComponent={Transition}
        scroll={'body'}
    >
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            whiteSpace: 'break-spaces',
            height: '100%'
        }}>
            <IconButton component={Link} sx={{
                marginLeft: 'auto',
                marginBottom: '31px'
            }} to={'/'}><CloseIcon/></IconButton>

            <Typography sx={{fontSize: 24, fontWeight: 600, paddingBottom: '42px'}}>Meet the team</Typography>

            <Box className={styles.base} sx={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: "center",
                width: '100%',
                padding: '49px 10px 35px',
                boxSizing: 'border-box',
                borderRadius: '16px'
            }}>
                <Typography sx={{
                    fontSize: 20,
                    fontWeight: 400,
                    paddingBottom: '24px',
                    maxWidth: '70%',
                    whiteSpace: 'noWrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>{data?.data?.position?.teamName}</Typography>
                <div className={styles.icons}/>
                {data?.data?.position?.teamLeader && <Worker worker={data?.data?.position?.teamLeader[0]}/>}
                <Box sx={{
                    flex: '1',
                    display: 'flex',
                    gap: '16px',
                    justifyContent: "center",
                    flexWrap: 'wrap',
                    width: '100%',
                }}>
                    {data?.data?.position?.teamMembers.map(worker => {
                        return <Worker worker={worker}/>
                    })}
                </Box>
            </Box>
        </Box>
    </Dialog>
}

export default MeetTheTeam;