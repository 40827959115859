import React from 'react';
import Dialog from "@mui/material/Dialog";
import {netAppTlvTitle, reasonsToWorkWithUsTitle, Transition} from "../Components/FullPageWelcom";
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {ReactComponent as CloseIcon} from "../assets/close.svg";
import youtube from '../assets/youtube-w.png';
import facebook from '../assets/facebook-w.png';
import twitter from '../assets/twitter-w.png';
import instagram from '../assets/instagram-w.png';
import linkedin from '../assets/linkedin-w.png';
import glassdoor from '../assets/glassdoor-w.png';
import NetappLogo from "../assets/netapp_logo_black.png";
import spotify from "../assets/spotify.png";
import apple from "../assets/apple.png";
import useGetCandidate from "../hooks/useGetCandidate";


const Story = (props) => {
    const {data} = useGetCandidate()
    const aboutUs = data?.data?.position?.aboutUs[0];

    return <Dialog
        fullScreen
        open={true}
        TransitionComponent={Transition}
        scroll={'body'}
    >
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            whiteSpace: 'break-spaces',
        }}>
            <IconButton component={Link} sx={{
                marginLeft: 'auto',
                marginBottom: '31px'
            }} to={'/'}><CloseIcon/></IconButton>

            <Typography sx={{fontSize: 24, fontWeight: 600, paddingBottom: '30px'}}>At the media</Typography>
            {data?.data?.netappStories.map((story) => {
                return <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                    marginBottom: '68px'
                }}>
                    <Typography sx={{width: '288px', textAlign: 'center'}}>
                        {story.text}
                    </Typography>
                    <a href={story.link} target={'_blank'} rel="noreferrer">
                        <img src={story.img}
                             alt={''}
                             loading="lazy"
                             style={{borderRadius: '50%', width: '68px', height: '68px'}}/>
                    </a>
                </Box>
            })}
            <iframe width="80%" height="315" src="https://www.youtube.com/embed/osoD5yXvYOA"
                    style={{borderRadius: '32px'}}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen/>

            <Typography sx={{
                margin: '86px 0 55px',
                color: '#027FF1',
                fontWeight: 600,
                fontSize: '24px',
            }}>Check us on social</Typography>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gap: '27px 52px',
                paddingBottom: '80px'
            }}>
                <Link target={'_blank'} to={'https://www.youtube.com/channel/UCSfUULe06JKXeXTVzCwcwhg'}><img src={youtube} loading={'lazy'} alt={'youtube'}/></Link>
                <Link target={'_blank'} to={'https://www.facebook.com/netappil'}><img src={facebook} loading={'lazy'} alt={'facebook'}/></Link>
                <Link target={'_blank'} to={'https://twitter.com/netappclouddata'}><img src={twitter} loading={'lazy'} alt={'x'}/></Link>
                <Link target={'_blank'} to={'https://www.instagram.com/netapp_cloud_tlv/'}><img src={instagram} loading={'lazy'} alt={'instagram'}/></Link>
                <Link target={'_blank'} to={'https://www.linkedin.com/company/netapp-public-cloud-services/'}><img src={linkedin} loading={'lazy'} alt={'linkedin'}/></Link>
                <Link target={'_blank'} to={'https://www.glassdoor.com/Reviews/NetApp-Tel-Aviv-Reviews-EI_IE5406.0,6_IL.7,15_IS5078.htm'}><img src={glassdoor} loading={'lazy'} alt={'glassdoor'}/></Link>
                <Link target={'_blank'} to={'https://open.spotify.com/show/5cS10s2CWb2zUCsGKIpfKw?si=e2f76c7c6970485b&nd=1'}><img src={spotify} loading={'lazy'} alt={'glassdoor'}/></Link>
                <Link target={'_blank'} to={'https://podcasts.apple.com/il/podcast/%D7%A9%D7%9C-%D7%9E%D7%99-%D7%94%D7%A7%D7%95%D7%93-%D7%94%D7%96%D7%94/id1692076639'}><img src={apple} loading={'lazy'} alt={'apple'}/></Link>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px'
            }}>
                <Typography sx={{fontSize: '16px', fontWeight: 600}}>welcome to</Typography>
                <img src={NetappLogo} alt={'logo'} style={{paddingBottom: '24px'}}/>
                <Typography sx={{fontSize: '16px', paddingBottom: '36px'}}>{aboutUs.generalInfo}</Typography>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#027FF1',
                    paddingBottom: '16px'
                }}>{netAppTlvTitle}</Typography>
                <Typography sx={{fontSize: '16px', paddingBottom: '24px'}}>{aboutUs.inTelAviv}</Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#027FF1',
                    paddingBottom: '16px'
                }}>{reasonsToWorkWithUsTitle}</Typography>
                <Typography sx={{fontSize: '16px'}}>{aboutUs.reasons}</Typography>
            </Box>
        </Box>
    </Dialog>
}

export default Story;