
import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {}
    },
    img: {
        outline: "none"
    }
}));


const ImageModal = ({image, handleClose, open}) => {
    const classes = useStyles();
    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open} timeout={500} className={classes.img}>
                <img
                    src={image.img}
                    alt=""
                    style={{maxHeight: "90%", maxWidth: "90%",zIndex:'1',borderRadius:'16px'}}
                />
            </Fade>
        </Modal>
    );
}

export default ImageModal;